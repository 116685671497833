import { PendingUploadInfo } from "@/modules/upload_files/interfaces";
import { ReduxThunkAction, selectMainState } from "../..";
import { mainSlice } from "./mainSlice";
import { ActiveUser, ListOfLocationsToAnalyst } from "./interfaces";



// const serverUrl = window.location.hostname === 'localhost' ? 'http://localhost:3335' : 'https://api.well-whisper.com';
const serverUrl = 'https://api.well-whisper.com'
// const serverUrl = 'http://localhost:3335'

export const login = (email: string, password: string): ReduxThunkAction =>
    async (dispatch, getState) => {
        dispatch(mainSlice.actions.updateState({ status: true }));

        let result = []

        // const serverUrl = process.env.NEXT_PUBLIC_BACK_HOST

        console.log({ serverUrl })

        try {
            const response = await fetch(`${serverUrl}/api/users/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            const status = data?.status;

            if (status !== 200) {
                dispatch(mainSlice.actions.addToastNotification({
                    id: `${mainSlice.actions.addToastNotification.length + 1}`,
                    message: "No se pudo validar credenciales, favor de verificar tus credenciales",
                    type: "error",
                    duration: 5000,
                }));
                dispatch(mainSlice.actions.updateState({ status: false }));
                return
            }

            const token = data.token;
            localStorage.setItem('authToken', token); // Store the token in local storage

            const activeUser: ActiveUser = {
                id: data.id,
                name: data.name,
                email: data.email,
                role: data.role,
            };

            dispatch(mainSlice.actions.activeUser(activeUser));
        } catch (error) {
            dispatch(mainSlice.actions.activeUser());
            console.log({ error });
        }

        dispatch(mainSlice.actions.updateState({ status: false }));
    };

export const validateToken = (): ReduxThunkAction =>
    async (dispatch, getState) => {

        dispatch(mainSlice.actions.updateState({ status: true }));

        console.log("Validate Token");

        // const serverUrl = process.env.NEXT_PUBLIC_BACK_HOST

        console.log({ serverUrl })

        try {
            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                dispatch(mainSlice.actions.activeUser());
                return;
            }

            const response = await fetch(`${serverUrl}/api/users/login-with-token`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
            });

            const data = await response.json();

            const status = data?.status;

            if (status !== 200) {
                localStorage.removeItem("authToken");
                dispatch(mainSlice.actions.activeUser());
                return;
            }

            const newToken = data.token;
            localStorage.setItem('authToken', newToken); // Store the token in local storage

            const activeUser: ActiveUser = {
                id: data.id,
                name: data.name,
                email: data.email,
                role: data.role,
            };

            dispatch(mainSlice.actions.activeUser(activeUser));
        } catch (error) {
            dispatch(mainSlice.actions.activeUser());
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));

        }

    };

export const logout = (): ReduxThunkAction =>
    async (dispatch, getState) => {

        localStorage.removeItem("authToken");
        dispatch(mainSlice.actions.activeUser());
    };

export const getDepartmentInfo = (departmentId: string): ReduxThunkAction =>
    async (dispatch, getState) => {

        // const serverUrl = process.env.NEXT_PUBLIC_BACK_HOST

        // console.log({ serverUrl })

        // console.log("Getting department info");

        dispatch(mainSlice.actions.updateState({ status: true }));

        try {
            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                return;
            }

            const response = await fetch(`${serverUrl}/api/workflow/GetDepartmentInformation?id=${departmentId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
            });

            const data = await response.json();

            const status = response?.status;

            if (status !== 200) {
                console.log('Error')
            }

            const department = data;

            dispatch(mainSlice.actions.setActiveDepartment(department));
        } catch (error) {
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));
        }

    };

export const getUsersList = (): ReduxThunkAction =>
    async (dispatch, getState) => {

        // const serverUrl = process.env.NEXT_PUBLIC_BACK_HOST

        console.log({ serverUrl })
        console.log("Getting getUsersList");

        dispatch(mainSlice.actions.updateState({ status: true }));

        try {
            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                return;
            }

            const response = await fetch(`${serverUrl}/api/users/listOfUsers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
            });

            if (response?.status !== 200) {
                console.log('Error')
            }

            const data = await response.json();

            dispatch(mainSlice.actions.setUsersList(data));
        } catch (error) {
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));
        }

    };

export const createTaskWithFiles = (props: PendingUploadInfo): ReduxThunkAction =>
    async (dispatch, getState) => {


        try {
            // const serverUrl = process.env.NEXT_PUBLIC_BACK_HOST

            console.log({ serverUrl })

            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                dispatch(mainSlice.actions.setUploadingStep('Error Uploading'));
                return;
            }

            dispatch(mainSlice.actions.setUploadingStep('Creating Task'));

            const response = await fetch(`${serverUrl}/api/tasks/createTask`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
                body: JSON.stringify({ taskId: props.taskId, sprintId: props.sprintId, type: props.type, teamMembersId: props.teamMembersIds, totalFiles: props.totalFiles }),
            });

            console.log({ response })

            const taskCreated = await response.json();

            if (response?.status !== 200 && response?.status !== 201) {
                console.log('Error')
                dispatch(mainSlice.actions.setUploadingStep('Error Uploading'));
                return;
            }

            const sortedFiles = props?.selectedFiles?.sort((a, b) => {
                const excelMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                if (a.fileType === excelMimeType) {
                    return -1;
                } else if (a.fileType !== excelMimeType && b.fileType === excelMimeType) {
                    return 1;
                }
                return 2
            });

            for (const element of sortedFiles || []) {

                dispatch(mainSlice.actions.setUploadingStep(`Uploading File ${element.fileName}`));

                const formData = new FormData();
                formData.append('file', element.file);

                const headers = {
                    authorization: `${token}`,
                    taskId: taskCreated.id,
                };

                const response = await fetch(`${serverUrl}/api/tasks/uploadFileToTask`, {
                    method: 'POST',
                    headers,
                    body: formData,
                });

                if (response?.status !== 200 && response?.status !== 201) {
                    console.log('Error')
                    dispatch(mainSlice.actions.setUploadingStep(`Error Uploading`));
                    return;
                }

            }

            dispatch(mainSlice.actions.setUploadingStep(`Task Uploaded`));

        } catch (error) {
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));
        }

    };

export const getTaskList = (page = 0, limit = 100, type = ''): ReduxThunkAction =>
    async (dispatch, getState) => {

        dispatch(mainSlice.actions.updateState({ status: true }));

        // const serverUrl = process.env.NEXT_PUBLIC_BACK_HOST

        console.log({ serverUrl })

        try {
            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                return;
            }

            const headers = {
                "Content-Type": "application/json",
                authorization: `${token}`,
                page: `${page}`,
                limit: `${limit}`,
                projectId: `${getState().main.activeProjectId}`,
                typeOfTask: type
            }

            const response = await fetch(`${serverUrl}/api/tasks/getTasksPaginated`, {
                method: "GET",
                headers
            });

            if (response?.status !== 200 && response?.status !== 201) {
                console.log('Error')
            }

            const data = await response.json();

            dispatch(mainSlice.actions.setTasksList(data));
        } catch (error) {
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));
        }

    };

export const downloadFileReport = (fromDate: string, toDate: string): ReduxThunkAction =>
    async (dispatch, getState) => {

        dispatch(mainSlice.actions.updateState({ status: true }));

        try {
            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                return;
            }

            const headers = {
                "Content-Type": "application/json",
                authorization: `${token}`,
                fromDate,
                toDate,
                departmentId: `${getState().main.activeDepartmentId}`,
                projectId: `${getState().main.activeProjectId}`,
            }

            const response = await fetch(`${serverUrl}/api/tasks/getReportsByDate`, {
                method: "GET",
                headers
            });

            console.log({ response })

            if (response?.status !== 200 && response?.status !== 201) {
                dispatch(mainSlice.actions.addToastNotification({
                    id: `${mainSlice.actions.addToastNotification.length + 1}`,
                    message: "No se pudo generar el reporte",
                    type: "error",
                    duration: 5000,
                }));
            } else {
                const data = await response.json();
                console.log({ data });
                dispatch(mainSlice.actions.addToastNotification({
                    id: `${mainSlice.actions.addToastNotification.length + 1}`,
                    message: data?.message,
                    type: "success",
                    duration: 5000,
                }));
            }

        } catch (error) {
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));
        }

    }


export const getDataForAnalyst = (fromDate: string, toDate: string): ReduxThunkAction =>
    async (dispatch, getState) => {

        dispatch(mainSlice.actions.updateState({ status: true }));

        try {
            let token = localStorage.getItem("authToken");

            if (!token) {
                console.log("No token");
                return;
            }

            const headers = {
                "Content-Type": "application/json",
                authorization: `${token}`,
                fromDate,
                toDate,
                departmentId: `${getState().main.activeDepartmentId}`,
                projectId: `${getState().main.activeProjectId}`,
            }

            const response = await fetch(`${serverUrl}/api/workflow/GetActivitiesWithCards`, {
                method: "GET",
                headers
            });

            console.log({ response })

            if (response?.status !== 200 && response?.status !== 201) {
                dispatch(mainSlice.actions.addToastNotification({
                    id: `${mainSlice.actions.addToastNotification.length + 1}`,
                    message: "Error bajando Data",
                    type: "error",
                    duration: 5000,
                }));
            } else {

                const listOfLocations:ListOfLocationsToAnalyst[] = []

                const data = await response.json();

                const sortData = data.sort((a:any, b: any) => {
                    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                  });

                for (const actSort of sortData) {

                    const exist = listOfLocations.findIndex(x=>x.id === actSort?.location?.id)

                    const opeStatus = actSort?.variablesValues?.find((x:any) => x.typeVariable.id === "b75159f7-752a-11ef-9499-9c6b00614d7d")?.value
                    const activityType = actSort?.variablesValues?.find((x:any) => x.typeVariable.id === "b75159dc-752a-11ef-9499-9c6b00614d7d")?.value

                    if (exist>-1){
                        listOfLocations[exist].activities.push({
                            id: actSort.id,
                            location: actSort?.location?.name,
                            status: actSort?.process,
                            completedAt: actSort?.createdAt,
                            activityType,
                            opeStatus
                        })
                    }else{
                        listOfLocations.push({
                            id: actSort?.location?.id,
                            name: actSort?.location?.name,
                            status:  actSort?.process,
                            completedAt: actSort?.createdAt,
                            pot: actSort?.location?.pot,
                            activityType,
                            opeStatus,
                            activities: [{
                                id: actSort.id,
                                location: actSort?.location?.name,
                                status: actSort?.process,
                                completedAt: actSort?.createdAt,
                                activityType,
                                opeStatus
                            }]
                        })
                    }
                    
                }

                dispatch(mainSlice.actions.setListOfLocation(listOfLocations));

            }

        } catch (error) {
            console.log({ error });
        } finally {
            dispatch(mainSlice.actions.updateState({ status: false }));
        }

    }