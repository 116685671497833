import './styles.scss';
import ButtonCircle from '../../components/button_circle'; // Correctly import the 'button_circle' component
import { useEffect, useState } from 'react';
import ButtonBig from '../../components/button_big';
import { useSelector } from 'react-redux';
import { mainSlice, selectMainState, useDispatch } from '../../stateManagement/redux';
import { logout } from '../../stateManagement/redux/slices/main/asyncReducers';
import ButtonTransparent from '../../components/button_transparent';
import { Project } from '../../stateManagement/redux/slices/main/interfaces';

export const Header = (props: { children?: React.ReactNode }) => {

  const mainState = useSelector(selectMainState)

  const [activeProjectName, setActiveProjectName] = useState<Project>()

  // const [width, setWidth] = useState(0);
  // const handleResize = () => {
  //   setWidth(window.innerWidth)
  // };
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [width]);

  useEffect(() => {
    const project = mainState.activeDepartment?.projects.find(project => project.id === mainState.activeProjectId)
    if (project) {
      setActiveProjectName(project)
    }
  }, [mainState.activeProjectId])


  const [showProjectSelection, setShowProjectSelection] = useState(false)

  const onClickProjectSelection = () => {
    setShowProjectSelection(!showProjectSelection)
  }


  // if (width > 500) {
  return (
    <>
      {showProjectSelection && <ProjectSelectionMenu onClose={onClickProjectSelection} />}
      {mainState?.showUserMenu && <UserMenu />}
      <div className='header'>
        <div className='header__logo'>
          <img src="/assets/icons/logo_without_text.svg" alt='logo' height={60} width={40} />
          <img src="/assets/icons/logo_text.svg" alt='logoText' height={40} width={90} />
        </div>
        <SearchBar />
        <div className='header__activeProject_button' onClick={onClickProjectSelection}>
          {activeProjectName&&activeProjectName.name}
        </div>
        <div className='header__icons_section'>
          <UserCard />
        </div>

      </div>
    </>
  );
  // } else {
  //   return (
  //     <>
  //       {mainState?.showUserMenu && <UserMenu />}
  //       <div className='header_mobile'>
  //         <div className='header_mobile__logo'>
  //           <img src="/assets/icons/logo_without_text.svg" alt='logo' height={60} width={40} />
  //         </div>
  //         <SearchBar />
  //         <div className='header_mobile__icons_section'>
  //           <UserCard />
  //         </div>

  //       </div>
  //     </>
  //   );
  // }

};

const UserMenu = () => {

  const dispatch = useDispatch();

  const onClickMenu = () => {
    dispatch(mainSlice.actions.openCloseUserMenu())
  }

  const onLogOut = () => {
    dispatch(logout())
  }


  const mainState = useSelector(selectMainState)

  return (
    <div className='header__background_menu'>
      <div className='header__menu'>
        {/* items header */}
        <div className='header__menu__top_item'>
          <div>Mi Cuenta</div>
          <div className='header__menu__top_item__close_icon' onClick={() => onClickMenu()}>
            <img src="/assets/icons/close.svg" alt='logo' height={15} width={15} />
          </div>
        </div>
        {/* User card */}
        <div className='header__menu__user_card'>
          <div className='header__userCard_profile_image' >
            {mainState?.activeUser?.name?.slice(0, 2)}
          </div>
          <div>
            <div className='header__userCard__name'>{mainState?.activeUser?.name}</div>
            <div className='header__userCard__detail'>{mainState?.activeUser?.email}</div>
          </div>
        </div>
        <div className='header__menu____options_title'>Opciones</div>
        <div className='header__menu__options_list'>
          <ButtonBig text='Cerrar Sesión' icon="/assets/icons/logout.svg" onClick={() => onLogOut()} />
        </div>

      </div>
    </div>

  )

}

const ProjectSelectionMenu = ({ onClose }: { onClose: () => void }) => {

  const dispatch = useDispatch();

  const onClick = (activeProject: string) => {
    dispatch(mainSlice.actions.setActiveProjectId(activeProject))
    localStorage.setItem('activeProjectId', activeProject)
    onClose()
  }

  const mainState = useSelector(selectMainState)

  return (
    <div className='header__background_menu'>
      <div className='header__menu'>
        {/* items header */}
        <div className='header__menu__top_item'>
          <div>Seleccione una opcion</div>
          <div className='header__menu__top_item__close_icon' onClick={() => onClose()}>
            <img src="/assets/icons/close.svg" alt='logo' height={15} width={15} />
          </div>
        </div>
        <div className='header__option_separator' />
        <div className='header__menu__options_list'>
          {mainState.activeDepartment?.projects.map((project, index) => (
            <ButtonTransparent text={project.name} onClick={() => onClick(project.id)} />
          ))}
        </div>

      </div>
    </div>

  )

}

const UserCard = () => {

  const dispatch = useDispatch();

  const onClickMenu = () => {
    dispatch(mainSlice.actions.openCloseUserMenu())
  }

  const mainState = useSelector(selectMainState)

  return (
    <div className='header__userCard' onClick={() => onClickMenu()}>
      {/* <img src="/assets/icons/dummy_image.svg" alt='logo' height={35} width={35} /> */}
      <div className='header__userCard_profile_image' >
        {mainState?.activeUser?.name?.slice(0, 2)}
      </div>
      <div>
        <div className='header__userCard__name'>{mainState?.activeUser?.name}</div>
        <div className='header__userCard__detail'>{mainState?.activeUser?.email}</div>
      </div>
    </div>
  );
}

const SearchBar = (props: { children?: React.ReactNode }) => {
  return (
    <div className='header__search'>
      <img src="/assets/icons/search.svg" alt='logo' height={25} width={25} />
      <input className='header__search__input' type='text' placeholder='Search' />
    </div>
  )
}

