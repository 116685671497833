 export interface ActiveUser{
    id: string,
    name: string,
    email: string,
    role: 'admin' | 'user' | 'Ing. de Campo' | 'Tecnico Campo'
  }
  
  export enum DepartmentNameEnum {
    wellWhisper = '33909508-729a-11ef-b5c1-c9abfae58c0d',
    rig = 'rig',
    tanks = 'tanks'
  }
  
  export enum BaseStatusEntityEnum {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    DELETED = 'DELETED',
    LOCKED = 'LOCKED',
  }
  
  export interface Sprint {
    id:string,
    code:string,
    name:string,
    status:BaseStatusEntityEnum,
  }
  
  export interface Backlog {
    id:string,
    code:string,
    name:string,
    status:BaseStatusEntityEnum,
    sprints: Sprint[],
  }
  
  export interface Project {
    id:string,
    code:string,
    name:string,
    status:BaseStatusEntityEnum,
    backlogs: Backlog[],
  }
  
  export interface ActiveDepartment {
    id:string,
    code:string,
    name:string,
    status:BaseStatusEntityEnum,
    projects: Project[],
  }

  export interface Subtask {
    id:string,
    status:BaseStatusEntityEnum,
    resourceLink:string,
    process:string,
    errors:string[],
  }

  export interface File {
    id:string,
    status:BaseStatusEntityEnum,
    detail:string,
    url_link:string,
    subtask: Subtask,
  }

  export interface TeamMember {
    id:string,
    name:string,
    email:string,
  }

  export interface TaskList{
    id:string,
    createdAt:string,
    projectId:string,
    status:BaseStatusEntityEnum,
    backlog:Backlog,
    teamMembers:TeamMember[],
    process: string,
    type: string,
    totalFiles: number,
    totalProcessed: number,
    errorLink: string,
    templateUrls: string[],
    files: File[],
  }

  export interface ListOfActivities {
    id:string,
    location:string,
    status:string,
    completedAt:Date,    
    activityType:string,
    opeStatus:string,
  }

  export interface ListOfLocationsToAnalyst {
    id:string,
    name:string,
    status:string,
    completedAt:Date,
    pot:string,
    activityType:string,
    opeStatus:string,
    activities:ListOfActivities[]
  }
  
  export interface mainSliceState {
    activeDepartmentId?: DepartmentNameEnum|undefined,
    activeProjectId?: string,
    showMenu: boolean,
    activeUser?: ActiveUser,
    loading: boolean,
    showUserMenu: boolean,
    activeDepartment:ActiveDepartment | undefined, 
    usersList: ActiveUser[],
    uploadingStep?: string,
    taskList?: TaskList[],
    notifications?:ToastProps[],
    listOfLocation?: ListOfLocationsToAnalyst[]
  }

  export interface ToastProps {
    id: string;
    message: string;
    type: 'success' | 'error' | 'info';
    duration?: number;
  }