import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {reducers} from './syncReducers'
import { mainSliceState, ToastProps } from "./interfaces";


const initialState: mainSliceState = {
  activeDepartmentId: undefined,
  showMenu: false,
  activeUser: undefined,
  loading: false,
  showUserMenu: false,
  activeDepartment:undefined,
  usersList: [],
  notifications: [],
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers,
});