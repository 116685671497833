import { useEffect } from "react";
import {
    useDispatch,
    useSelector,
    mainSlice,
    selectMainState,
} from "../../stateManagement/redux";

import './styles.scss'
import { ToastProps } from "@/stateManagement/redux/slices/main/interfaces";


const Toast = ({ toastMessage }: { toastMessage: ToastProps }) => {

    const { id, message, duration, type } = toastMessage;

    const dispatch = useDispatch();

    const onClose = () => {
        console.log(`Should clean notification ${id}`)
        dispatch(mainSlice.actions.removeToastNotification(id))
    }

    useEffect(() => {
        const timer = setTimeout(onClose, duration);
        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div className={`
            ${toastMessage.type === "error" ? "toast_error": 
            toastMessage.type=== "success" ? "toast_success"
            :"toast_info"}`} >
            <div>{message}</div>
            <div className="toast_close_button">
                <img src="/assets/icons/close.svg" alt="close" onClick={onClose} height={10} width={10}/>
            </div>
        </div >
    );

}


export const ToastNotification = () => {

    const mainState = useSelector(selectMainState);

    return (
        <div className='toast_notification_container'>
            {mainState?.notifications?.map((msg, i) => (<Toast key={i} toastMessage={msg} />))}
        </div>)
}