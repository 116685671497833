import React from 'react'

import './styles.scss'

interface Props {
  icon?: any;
  text: string;
  onClick?: () => void
  iconHeight?: number;
  iconWidth?: number;
  notification?: boolean;
}

export default function ButtonTransparent(props: Props) {
  return (
    <div className='button_transparent_external'>
      <div className='button_transparent' onClick={props.onClick}>
        {props.icon && (
          <img src={props.icon} alt='logo' height={props.iconHeight || 30} width={props.iconWidth || 30} />
        )}
        <div className='button_transparent__text'>{props.text}</div>
        {props.notification && <div className='button_transparent__alert' />}
      </div>
    </div>
  )
}
