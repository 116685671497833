import { WrapMain } from '../../../modules/wrap_main'
import { ErrorComponent } from '../../../components/error'
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import ButtonBig from '../../../components/button_big';
import ButtonCircle from '../../../components/button_circle';

import './styles.scss';
import '../../../styles/globalstyles.scss';
import { useDispatch } from '../../../stateManagement/redux';
import { downloadFileReport, getDataForAnalyst } from '../../../stateManagement/redux/slices/main/asyncReducers';
import ListOfLocations from './locations_list'


const ModalReports = ({ startDate, endDate, onClose }: { startDate: string, endDate: string, onClose: () => void }) => {

  const dispatch = useDispatch();

  const downloadFieldReportOnClick = () => {
    dispatch(downloadFileReport(startDate, endDate));
    onClose();
  }

  const getDataForDashboard = () => {
    dispatch(getDataForAnalyst(startDate, endDate))
    onClose();
  }


  const reportsOptionsList = [
    { name: 'Descargar Reporte unificado de Campo', onclick: downloadFieldReportOnClick },
    { name: 'Consultar Data para Dashboard', onclick: getDataForDashboard },
  ]


  return (
    <div className='download_report_modal_container'>
      <div className='download_report_modal_container_form_container'>
        <div className='download_report_modal_container_form_container_close_section'>
          <ButtonCircle icon="/assets/icons/close.svg" iconHeight={15} iconWidth={15} onClick={onClose} />
        </div>
        <div className='download_report_modal_container_form_container_info_section'>
          <img src='/assets/icons/download.svg' alt='' width={60} height={80} />
          <div className='download_report_modal_container_form_container_title'>Consultas Disponibles</div>
          <div className='download_report_modal_container_form_container_subtitle'>Selecciona una opcion para el periodo del {startDate} hasta {endDate}</div>
        </div>
        <div className='download_report_modal_container_options_container'>
          {reportsOptionsList?.map((item, index) =>
          (<div key={index} className='download_report_modal_container_options_container_option' onClick={item.onclick}>
            {item.name}
          </div>))}
        </div>
      </div>
    </div>)
}


const Tabs = ({ tabs, activeTabs, setActive }: { tabs: any[], activeTabs: string, setActive: any }) => {

  return (
    <div className='tabs_container'>
      {tabs.map((t, i) => (
        <div key={i} className={`tabs_container_tab ${activeTabs === t.id && " active"}`} onClick={() => setActive(t.id)} >
          {t.name}
        </div>))}
    </div>)
}

const Dashboard = () => {

  const tabs = [
    { id: "dashboard", name: "Dashboard" },
    { id: "analyze", name: "Analisis" },
    { id: "listLocations", name: "Lista de Locaciones" },
  ]

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [showModal, setShowModal] = useState(false);

  const [activeTab, setActiveTab] = useState("analyze")

  return (
    <>
      {showModal && (
        <ModalReports
          startDate={startDate.toISOString().split('T')[0]}
          endDate={endDate.toISOString().split('T')[0]}
          onClose={() => setShowModal(false)}
        />)}
      <div className='dashboard_container'>
        <div className='dashboard_container_filter_container'>
          <div>
            <label className='dashboard_container_label'>Fecha Inicio</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => setStartDate(date || new Date())}
              dateFormat="yyyy/MM/dd"
            />
          </div>
          <div>
            <label className='dashboard_container_label'>Fecha Fin</label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => setEndDate(date || new Date())}
              dateFormat="yyyy/MM/dd"
            />
          </div>
          <ButtonBig text='Consultar' onClick={() => setShowModal(true)} />
        </div>
        <Tabs tabs={tabs} activeTabs={activeTab} setActive={setActiveTab} />

        {activeTab === "dashboard" && <ErrorComponent type='403' infoPage='Moodulo No Disponible' />}

        {activeTab === "listLocations" && <ErrorComponent type='403' infoPage='Moodulo No Disponible' />}

        {activeTab === "analyze" && (<ListOfLocations />)}


      </div>
    </>)
}

const index = () => {
  return (
    <WrapMain>
      <Dashboard />
    </WrapMain>
  )
}

export default index