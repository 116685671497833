import React, { useEffect, useState } from 'react'
import { selectMainState, useDispatch, useSelector } from '../../../stateManagement/redux';
import { ListOfLocationsToAnalyst } from '@/stateManagement/redux/slices/main/interfaces';
import ButtonBig from '../../../components/button_big';
import WellAnalyst from '../../../modules/well_analyst'

const StatesTabs = ({ tabs, activeState, setActiveState }: { tabs: any[], activeState: string, setActiveState: any }) => {
    return (
        <div className='tabs_state'>
            <div className='text_label' >Estado de las locaciones : </div>
            {tabs.map((t, i) => (
                <div className={`tab ${t.id === activeState && "active"}`} key={i} onClick={() => setActiveState(t.id)}>
                    <div>{t.name}</div>
                    <div className={`numberItems ${t.id === activeState && "active"}`} >{t.amountElement}</div>
                </div>
            ))}
        </div>)
}


const TableListAnalyst = ({activeLocations}:{activeLocations:ListOfLocationsToAnalyst[]}) => {


    const [activeLocation, setActiveLocation] = useState<ListOfLocationsToAnalyst>()

    const [showWellAnalyst, setShowWellAnalyst] = useState(false)

    const onClickLocation = (location: ListOfLocationsToAnalyst) => {
        if (activeLocation?.id === location.id) {
            setActiveLocation(undefined)
        } else {
            setActiveLocation(location)
        }
    }

    const showLocationDetail = (location: ListOfLocationsToAnalyst) => {
        console.log({ location })
    }

    return (
        <>
        {showWellAnalyst && <WellAnalyst />}
        <div className='table_analyst'>
            {/* headers */}
            <div className='header'>
                <div>Locacion</div>
                <div>Ope Status</div>
                <div>Estado</div>
                <div>Fecha</div>
                <div>Pot</div>
                <div>Actividad</div>
                <div />
            </div>
            {/* body */}
            {activeLocations?.map((location, i) => {

                const dateActivity = location?.completedAt?.toString()?.split("T")[0]

                return (
                    <div key={i} className='body_row'>
                        <div className='body_location'>
                            <div>{location?.name}</div>
                            <div>{location?.opeStatus}</div>
                            <div>{location?.status}</div>
                            <div>{dateActivity}</div>
                            <div>{location?.pot || 0}</div>
                            <div>{location?.activityType}</div>
                            <div>
                                <ButtonBig text='Analizar' onClick={() => showLocationDetail(location)} />
                            </div>
                        </div>

                        {location?.activities?.length > 0 && (
                            <div className='body_detail_container' onClick={() => onClickLocation(location)}>
                                {location?.activities?.length} Visitas
                                <img src={activeLocation?.id === location.id ? "/assets/icons/arrow_up.svg" : "/assets/icons/arrow_down.svg"} width={20} height={20} alt='' />
                            </div>
                        )}
                        {
                            activeLocation?.id === location.id && (
                                <>
                                    <div className='sub_table_container'>

                                        <div className='sub_table_header'>
                                            <div>Fecha</div>
                                            <div>Ope Status</div>
                                            <div>Estado</div>
                                            <div>Actividad</div>
                                            <div>Cluster</div>
                                            <div>Opciones Optimizacion</div>
                                        </div>

                                        {location?.activities?.map((activity, i) => {

                                            const dateActivity = activity?.completedAt?.toString()?.split("T")[0]

                                            return (
                                                <div key={i} className='sub_table_row'>
                                                    <div>{dateActivity}</div>
                                                    <div>{activity?.opeStatus}</div>
                                                    <div>{activity?.status}</div>
                                                    <div>{activity?.activityType}</div>
                                                    <div>{'Reanalizar'}</div>
                                                    <div></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            )
                        }

                    </div>
                )
            })}
        </div>
        </>
    )
}

const LocationsList = () => {

    const mainState = useSelector(selectMainState);

    const [filterStates, setFilterStates] = useState([
        { id: "all", name: "Todos", amountElement: 0 },
        { id: "pending", name: "Pendientes", amountElement: 0 },
        { id: "finished", name: "Analizados", amountElement: 0 },
        { id: "analyzing", name: "En Evaluacion", amountElement: 0 },
    ])

    const [activeState, setActiveState] = useState(filterStates[0].id)

    useEffect(() => {
        console.log({ lo: mainState?.listOfLocation })

        let all = 0
        let pending = 0
        let analyzed = 0
        let analyzing = 0

        for (const element of mainState?.listOfLocation || []) {

            all = all + 1

            if (element?.status === "pending") {
                pending = pending + 1
            }

            if (element?.status === "finished") {
                analyzed = analyzed + 1
            }

            if (element?.status === "analyzing") {
                analyzing = analyzing + 1
            }
        }

        setFilterStates([
            { id: "all", name: "Todos", amountElement: all },
            { id: "pending", name: "Pendientes", amountElement: pending },
            { id: "finished", name: "Analizados", amountElement: analyzed },
            { id: "analyzing", name: "En Evaluacion", amountElement: analyzing },
        ])
    }, [mainState?.listOfLocation])


    const [activeLocations, setActiveLocations] = useState<ListOfLocationsToAnalyst[]>([])

    useEffect(() => {
        const selectedLocations = mainState?.listOfLocation?.filter((location) => {
            if (activeState === "all") {
                return true
            }
            return location.status === activeState
        })

        setActiveLocations(selectedLocations || [])

}, [activeState])



return (
    <>
        <StatesTabs tabs={filterStates} activeState={activeState} setActiveState={setActiveState} />
        <TableListAnalyst activeLocations={activeLocations} />
    </>
)
}

export default LocationsList