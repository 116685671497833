import './styles.scss';
import {  useSelector } from 'react-redux';
import { mainSlice, selectMainState, useDispatch } from '../../stateManagement/redux';
import { DepartmentNameEnum } from '../../stateManagement/redux/slices/main/interfaces';

interface Props {
  icon: any;
  onClick?: () => void
  iconHeight?: number;
  iconWidth?: number;
  notification?: boolean;
  activeDepartmentId: DepartmentNameEnum;
}

function Button_Projects(props: Props) {

  const mainState = useSelector(selectMainState);

  return (
    <div className={`${mainState?.activeDepartmentId === props.activeDepartmentId ? 'button_projects_active' : 'button_projects'}`} onClick={props.onClick}>
      {mainState?.activeDepartmentId === props.activeDepartmentId && <div className='button_projects__selectedIcon' />}
      <img src={props.icon} alt='logo' height={props.iconHeight || 50} width={props.iconWidth || 50} />
      {props.notification && <div className='button_projects__alert' />}
    </div>
  )
}


export const RightMenuProjects = (props: { children?: React.ReactNode }) => {

  const dispatch = useDispatch();

  const onClick = (value: DepartmentNameEnum) => {

    dispatch(mainSlice.actions.setProjectOnClick(value))

    // localStorage.setItem('activeDepartmentId', value)

    // dispatch(getDepartmentInfo(value))
  }

  return (
    <div className='right_menu_projects'>
      <Button_Projects icon="/assets/icons/well_whisper.svg" iconHeight={25} iconWidth={25} onClick={() => onClick(DepartmentNameEnum.wellWhisper)} activeDepartmentId={DepartmentNameEnum.wellWhisper} />
      <Button_Projects icon="/assets/icons/rigs.svg" iconHeight={25} iconWidth={25} onClick={() => onClick(DepartmentNameEnum.rig)} activeDepartmentId={DepartmentNameEnum.rig} />
      <Button_Projects icon="/assets/icons/tanks.svg" iconHeight={25} iconWidth={25} onClick={() => onClick(DepartmentNameEnum.tanks)} activeDepartmentId={DepartmentNameEnum.tanks} />
    </div>
  );
};